import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { FiltersManage } from "src/app/core/helpers/filters.manage";
import {
  FilterInputs,
  FilterSelectFields,
} from "src/app/core/interfaces/filters.interface";
import { IFilterData } from "src/app/core/models/filters.model";
import { RegionsService } from "src/app/core/services/regions.service";
import { UsersService } from "src/app/core/services/users.service";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
})
export class FiltersComponent
  extends FiltersManage
  implements OnInit, OnDestroy
{
  filterForm: FormGroup;
  @Input() filterData: IFilterData;
  @Input() _FilterInputs: FilterInputs[];
  @Input() _FilterSelect: FilterSelectFields[];
  OPTIONS: any[];
  formSubscription: Subscription;
  showActions = false;

  constructor(
    private router: Router,
    public regionsService: RegionsService,
    public usersService: UsersService
  ) {
    super(regionsService, usersService);
  }

  ngOnInit(): void {
    this.initForm();
    if (this.filterData) {
      this.setDataToForm(this.filterData);
    }
  }

  initForm() {
    this.filterForm = new FormGroup({});
    this.setForm(this.filterForm);

    // get form controllers and merge them into OPTIONS Array
    this.OPTIONS = [...this._FilterInputs, ...this._FilterSelect].map(
      (el) => el["formControl"]
    );

    // loop through the form controllers optons and set form controllers of form
    if (this.OPTIONS.length > 0) {
      this.OPTIONS.forEach((key) => {
        this.addControllerTOTheForm(key, null);
      });
    }

    this.filterActionsHandler();

    this._FilterSelect.length > 0
      ? this.SelectFieldsOptionsHandler(this.filterForm.controls)
      : "";
  }

  // function control show/hide of filter actions
  filterActionsHandler() {
    this.formSubscription = this.filterForm.valueChanges.subscribe((res) => {
      const formRes = { ...res };
      Object.keys(formRes).forEach((el) => {
        if (formRes[el] === null || formRes[el] === "") delete formRes[el];
      });

      if (Object.keys(formRes).length === 0) {
        this.showActions = false;
      } else {
        this.showActions = true;
      }
    });
  }

  // handle live search of  select fileds
  liveSearch(allowSearch, keyword, formController) {
    if (!allowSearch) return;
    this.SelectFieldsSearchHandler(formController, keyword);
  }

  Submit() {
    const filterOptions = { ...this.filterForm.value };

    // REMOVE the empty properties from the object before navigate
    Object.keys(filterOptions).forEach((key) => {
      if (filterOptions[key] == "null" || filterOptions[key] == null) {
        delete filterOptions[key];
      }
    });

    this.router.navigate([], { queryParams: filterOptions });
  }

  reset() {
    this.filterForm.reset();
    this.router.navigate([], { queryParams: {} });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }
}
