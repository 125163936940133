<form [formGroup]="filterForm" (ngSubmit)="Submit()">
  <div class="row">
    <!-- loop throw _FilterInputs options to render filter inputs -->
    <div class="col-md-2 mb-2" *ngFor="let input of _FilterInputs">
      <label [for]="input['formControl']">
        {{ input["label"] | translate }}
      </label>

      <input
        [id]="input['formControl']"
        [name]="input['formControl']"
        [type]="input['type']"
        class="form-control"
        [formControlName]="input['formControl']"
        [placeholder]="input['label'] | translate"
      />
    </div>

    <!-- loop throw _FilterSelect options to render filter select fields -->

    <div class="col-md-2 mb-2" *ngFor="let select of _FilterSelect">
      <label [for]="select['formControl']">
        {{ select["label"] | translate }}
      </label>

      <ng-select
        [formControlName]="select['formControl']"
        [placeholder]="select['label'] | translate"
        (keydown.enter)="
          liveSearch(
            select['allowSearch'],
            $event.target.value,
            select['formControl']
          )
        "
      >
        <ng-option
          *ngFor="let option of this[select['optionsProperty']]"
          [value]="option[select['optionsValue']]"
          >{{ option[select["optionsKey"]] | translate }}</ng-option
        >
      </ng-select>
    </div>

    <!--  filter actions buttons  -->
    <div class="col-4 d-flex align-items-end">
      <button
        type="submit"
        *ngIf="showActions"
        class="btn btn-primary w-sm ms-2 mb-2"
      >
        {{ "Search" | translate }}
      </button>

      <button
        type="button"
        class="btn btn-danger w-sm ms-2 mb-2"
        *ngIf="showActions"
        (click)="reset()"
      >
        {{ "Reset" | translate }}
      </button>
    </div>
  </div>
</form>
