import { take } from "rxjs/operators";
import { IUserData } from "../models/user.model";
import { FormManage } from "./formVaild";
import { RegionsService } from "../services/regions.service";
import { UsersService } from "../services/users.service";
import { ICityData } from "../models/regions.models";

export abstract class FiltersManage extends FormManage {
  users = [];
  drivers = [];
  countries = [];
  cities = [];

  activationOptions = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Inactive",
      value: 0,
    },
    {
      label: "All",
      value: 2,
    },
  ];

  transactionsStatusOptions = [
    {
      label: "pending",
      value: "pending",
    },
    {
      label: "success",
      value: "success",
    },
    {
      label: "failed",
      value: "failed",
    },
  ];

  constructor(
    public regionsService: RegionsService,
    public usersService: UsersService
  ) {
    super();
  }

  // get required live data from server
  SelectFieldsOptionsHandler(formControls) {
    if (formControls.user_id) {
      this.getAllUsers();
    }

    if (formControls.city_id) {
      this.getAllCities();
    }
  }

  SelectFieldsSearchHandler(formContoller, keyword) {
    switch (formContoller) {
      case "user_id":
        this.usersSearch(keyword);
        break;
    }
  }

  // users
  _userOptions: IUserData = { return_all: 1, type: "user" };
  getAllUsers() {
    this.usersService
      .getAllUsers(this._userOptions)
      .pipe(take(1))
      .subscribe((res) => (this.users = res.data));
  }

  usersSearch(keyword) {
    this._userOptions.keyword = keyword;
    this.getAllUsers();
  }

  // drivers
  _driverOptions: IUserData = { return_all: 1, type: "driver" };
  getAllDrivers() {
    this.usersService
      .getAllUsers(this._driverOptions)
      .pipe(take(1))
      .subscribe((res) => (this.drivers = res.data));
  }

  driversSearch(keyword) {
    this._driverOptions.keyword = keyword;
    this.getAllDrivers();
  }

  // cities
  _cityOptions: ICityData = { return_all: 1 };
  getAllCities() {
    this.regionsService
      .getAllCities(this._cityOptions)
      .subscribe((res) => (this.cities = res.data));
  }
  citySearch(keyword) {
    this._cityOptions.keyword = keyword;
    this.getAllCities();
  }
}
